import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from '../../../../../../analytics';
import API, { WithdrawError, WithdrawResponse } from '../../../../../../api';
import { mutationKeys } from '../../../../../../api/utils/mutationKeys';
import { queryKeys } from '../../../../../../api/utils/queryKeys';
import { ErrorId } from '../../../../../../constants';
import { AnalyticsEvents } from '../../../../../../constants/analytics';
import useModal from '../../../../../../hooks/useModal';
import { getCurrentCurrency } from '../../../../../../localization/constants';
import { routeMap } from '../../../../../../routes/routeMap';
import { environment } from '../../../../../../utils/env.utils';

const useWithdrawMutation = () => {
  const queryClient = useQueryClient();
  const { openModal } = useModal();
  const navigate = useNavigate();

  return useMutation<WithdrawResponse, WithdrawError, number>({
    mutationFn: amount =>
      API.paymentProvider.withdraw(
        amount,
        // We are adding a 30s timeout here so that we can display a modal if the request takes too long
        // When this timeout is hit we get error.code === 'ECONNABORTED'
        { timeout: 30000 }
      ),
    mutationKey: mutationKeys.withdraw,
    onSuccess: ({ transactionId }, amount) => {
      void trackEvent(AnalyticsEvents.WITHDRAWAL, { value: amount, currency: getCurrentCurrency() });

      void queryClient.refetchQueries({ queryKey: queryKeys.creditFeatures });
      void queryClient.refetchQueries({ queryKey: queryKeys.me });

      // Prefetch the withdrawal query so that we can display fairness receipt faster if required
      void queryClient.prefetchQuery({
        queryKey: queryKeys.withdrawalById(transactionId),
        queryFn: () => API.paymentProvider.getWithdrawalById(transactionId),
        staleTime: Infinity
      });

      openModal(`${environment.country}.WITHDRAWAL_SUCCESS`, {
        isPersistedFromRedirect: true,
        payload: { transactionId }
      });
      navigate(routeMap.credit.root);
    },
    onError: (error, amount) => {
      const errorId = error?.response?.data?.error?.errorId;

      if (errorId === ErrorId.INSUFFICIENT_FUNDS) {
        openModal('INSUFFICIENT_FUNDS');
      } else if (errorId === ErrorId.DIRECT_DEBIT_CHARGE_IN_PROGRESS) {
        openModal('DIRECT_DEBIT_CHARGE_IN_PROGRESS');
      } else if (errorId === ErrorId.OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS) {
        openModal('OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS');
      } else if (errorId === ErrorId.DIRECT_DEBIT_MANDATE_NOT_FOUND) {
        openModal('DIRECT_DEBIT_MANDATE_NOT_FOUND');
      } else if (errorId === ErrorId.WITHDRAW_NOT_APPROVED) {
        openModal('WITHDRAWAL_BLOCKED');
      } else if (error.code === 'ECONNABORTED') {
        openModal('WITHDRAWAL_IN_PROGRESS');
      } else if (
        errorId === ErrorId.WITHDRAW_AMOUNT_GREATER_THAN_MINIMUM ||
        errorId === ErrorId.WITHDRAW_AMOUNT_PLUS_FEE_HIGHER_THAN_LIMIT
      ) {
        openModal('WITHDRAWAL_AMOUNT_INVALID');
      } else {
        openModal('WITHDRAWAL_FAIL', { payload: { amount } });
      }
    }
  });
};

export default useWithdrawMutation;
