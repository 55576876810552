import { Text } from '@fairlo/ui-kit';
import { ComponentProps } from 'react';
import { WebTarget } from 'styled-components';

type TextPropsWithAs = ComponentProps<typeof Text> & { as?: WebTarget };

/**
 * @deprecated Use `<Text />` from `@fairlo/ui-kit` instead
 */
export const ModalParagraph = (props: TextPropsWithAs) => <Text color="gray-900" {...props} />;

export const ModalListItem = (props: TextPropsWithAs) => <Text as="li" color="gray-900" {...props} />;

export const TermsHeadingText = (props: TextPropsWithAs) => <Text as="span" weight="bold" {...props} />;
