import { Button, List, ListItem, ListItemText } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { formatInterestRate } from '../../../localization/percentages';
import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = { creditLimit: number; interest: number };
type Props = ModalProps<Payload>;

const LevlaOfferSuccess = ({ closeModal, payload: { creditLimit, interest } }: Props) => {
  const { t } = useTranslation(['Modal', 'Common', 'Button']);

  return (
    <Modal title={t('LevlaOfferSuccess.title')}>
      <ModalParagraph>{t('LevlaOfferSuccess.info')}</ModalParagraph>
      <FullWidthContainer>
        <List>
          <ListItem>
            <ListItemText text={t('LevlaOfferSuccess.infoItemTitle1')} />
            <ListItemText text={t('Common:money', { amount: creditLimit })} />
          </ListItem>
          <ListItem>
            <ListItemText text={t('LevlaOfferSuccess.infoItemTitle2')} />
            <ListItemText text={formatInterestRate(interest)} />
          </ListItem>
        </List>
      </FullWidthContainer>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LevlaOfferSuccess;
