import { environment } from '../utils/env.utils';
import { COUNTRY_MAP_LOCALE } from './constants';

export type FormatPercentageOptions = {
  withSign?: boolean;
  decimals?: number;
};

/**
 * Formats a number as a percentage string according to the current environment's locale.
 *
 * @param value - The number to be formatted as a percentage (e.g., 0.25 for 25%)
 * @param options - Configuration options for formatting
 * @param options.withSign - Whether to include the percentage sign in the output (defaults to true)
 * @param options.decimals - Number of decimal places to show (if undefined, uses locale default)
 * @returns A localized percentage string with or without the percentage sign
 *
 * @example
 * formatPercentage(0.25) // "25 %" (may vary by locale)
 * formatPercentage(0.25, { withSign: false }) // "25"
 * formatPercentage(0.25567, { decimals: 2 }) // "25.57 %" (may vary by locale)
 */
export const formatPercentage = (value: number, options: FormatPercentageOptions = {}) => {
  const { withSign = true, decimals } = options;

  const formatter = new Intl.NumberFormat(COUNTRY_MAP_LOCALE[environment.country], {
    style: 'percent',
    ...(decimals ? { minimumFractionDigits: decimals, maximumFractionDigits: decimals } : {})
  });

  const formatted = formatter.format(value);
  return withSign ? formatted : formatted.replace('%', '').trim();
};

export type FormatInterestRateOptions = {
  withSign?: boolean;
};

const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

/**
 * Formats an interest rate value as a percentage string according to the current environment's locale.
 *
 * @param value - The numerical value to format as an interest rate percentage (e.g., 0.25 for 25%)
 * @param options - Optional configuration for formatting
 * @param options.withSign - Whether to include a "%" sign in the output (default: true)
 * @returns A localized percentage string with 0 decimal places if the value is an integer, and 2 decimal places otherwise
 *
 * @example
 * formatInterestRate(0.156) // "15.60%"
 * formatInterestRate(0.15) // "15%"
 * formatInterestRate(0.15, { withSign: false }) // "15"
 */
export const formatInterestRate = (value: number, options: FormatInterestRateOptions = {}) => {
  const { withSign = true } = options;

  const rate = formatPercentage(value, {
    withSign,
    decimals: countDecimals(value) > 2 ? 2 : 0
  });

  return rate;
};
