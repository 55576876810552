import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { AnalyticsEvents } from './constants/analytics';
import { segment } from './integrations/segment';
import { DEFAULT_CURRENCY } from './localization/constants';
import { environment } from './utils/env.utils';
import { maskSensitiveData } from './utils/sensitiveData';

export async function trackUser(id: string) {
  Sentry.setUser({ id });
  LogRocket.identify(id);
  await segment.identify(id);
}

// Indicates that the user has logged out and should no longer be tracked
export const untrackUser = async () => {
  Sentry.setUser(null);
  await segment.reset();
};

export async function trackPageView() {
  await segment.page({
    version: environment.version
  });
}

export type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

export async function trackEvent(name: string, props?: TrackEventProperties) {
  const maskedProps = props ? maskSensitiveData(props) : undefined;
  // maskSensitiveData may return any property to a string, and LogRocket only
  // accepts numbers as revenue (not even undefined)
  // So we need to delete the revenue property if it's not a number
  const maskedPropsForLogRocket = { ...maskedProps };
  if (
    maskedPropsForLogRocket &&
    'revenue' in maskedPropsForLogRocket &&
    typeof maskedPropsForLogRocket.revenue !== 'number'
  ) {
    delete maskedPropsForLogRocket.revenue;
  }
  LogRocket.track(
    name,
    maskedPropsForLogRocket as Omit<typeof maskedProps, 'revenue'> & { revenue?: number }
  );
  await segment.track(name, maskedProps);
}

type FinancialEventOptions = Record<string, unknown> & {
  amount: number;
  agreementId: string;
};

export const trackFinancialEvent = async (
  name: string,
  { amount, agreementId, ...restOptions }: FinancialEventOptions
) =>
  trackEvent(name, {
    value: amount,
    revenue: amount, // Needed for reporting to Bing
    currency: DEFAULT_CURRENCY,
    agreementID: agreementId,
    ...restOptions
  });

type ApplicationFinancialEventOptions = Record<string, unknown> & {
  amount: number;
  id: string;
};

// Similar to trackFinancialEvent, but sends applicationdata_uuid instead of agreementID.
// Currently used only in the UK. The goal is to perform this transformation in the backend for SE as well and use this function instead of trackFinancialEvent.
export const trackApplicationFinancialEvent = async (
  name: string,
  { amount, id, ...restOptions }: ApplicationFinancialEventOptions
) =>
  trackEvent(name, {
    value: amount,
    revenue: amount, // Needed for reporting to Bing
    currency: DEFAULT_CURRENCY,
    applicationdata_uuid: id,
    ...restOptions
  });

export const trackAddToCartEvent = async (variant: 'Levla' | 'InitialCredit') =>
  trackFinancialEvent(AnalyticsEvents.ADD_TO_CART, {
    amount: 0,
    agreementId: '0',
    variant
  });

type SEApplicationStep =
  | 'personal-details'
  | 'employment-status'
  | 'payment-protection-insurance'
  | 'finances';
type UKApplicationStep =
  | 'personal-details'
  | 'address'
  | 'income'
  | 'outgoings'
  | 'credit-limit'
  | 'mobile-verification'
  | 'documents'
  | 'marketing-consent';

type SubmitCreditApplicationStepOptions = {
  applicantId: string;
  step: SEApplicationStep | UKApplicationStep;
};
export const trackSubmitCreditApplicationStep = (options: SubmitCreditApplicationStepOptions) =>
  trackEvent(AnalyticsEvents.SUBMIT_CREDIT_APPLICATION_STEP, options);
