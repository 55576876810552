import { AnchorButton, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { QueryParam } from '../../../constants';
import { routeMap } from '../../../routes/routeMap';
import { constructURL } from '../../../utils/url';
import Modal from '../Modal';

const RepayOutstandingBalance = () => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('RepayOutstandingBalance.title')}>
      <Text>{t('RepayOutstandingBalance.info')}</Text>

      <Modal.Actions>
        <AnchorButton
          as={Link}
          to={constructURL(routeMap.credit.deposit.root, {
            relative: true,
            queryParams: {
              [QueryParam.SELECT_MAX_AMOUNT]: 'true'
            }
          })}
        >
          {t('RepayOutstandingBalance.button')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default RepayOutstandingBalance;
