export const endpoints = {
  paymentProvider: {
    account: '/payment-provider/account',
    directPayment: {
      paymentId: (paymentId = ':paymentId') =>
        ({
          root: `/payment-provider/direct-payment/${paymentId}`,
          new: `/payment-provider/direct-payment/${paymentId}/new`,
          paymentRequest: {
            root: `/payment-provider/direct-payment/${paymentId}/payment-request`,
            new: `/payment-provider/direct-payment/${paymentId}/payment-request/new`
          },
          generateQr: `/payment-provider/direct-payment/${paymentId}/generate-qr`
        }) as const
    }
  }
} as const;
