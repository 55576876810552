import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';
import API from '../../../api';
import { showErrorToast } from '../../../components/Toast';
import { ErrorId } from '../../../constants';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';
import useLogout from '../../../hooks/useLogout';
import useMeQuery from '../../../hooks/useMeQuery';
import { selectRemainingDebt } from '../../../hooks/useMeQuery/selectors';
import useModal from '../../../hooks/useModal';
import { CustomUseMutationOptions } from '../../../types/reactQuery';
import { parseApiError } from '../../../utils/error';

const useTerminateCreditMutation = (
  options: CustomUseMutationOptions<AxiosResponse<void>, { reason: string }> = {}
) => {
  const { t } = useTranslation('ErrorToast');
  const { openModal, closeModal } = useModal();
  const logout = useLogout();
  const isLoggedIn = useIsLoggedIn();

  const { data: remainingDebt } = useMeQuery({ enabled: isLoggedIn, select: selectRemainingDebt });

  return useMutation({
    mutationFn: API.credit.terminateCredit,
    onSuccess: () => {
      closeModal();
      logout();
    },
    onError: error => {
      const { errorId } = parseApiError(error);

      switch (errorId) {
        case ErrorId.AGREEMENT_HAS_DEBT:
          openModal('AGREEMENT_HAS_DEBT', {
            payload: { remainingDebt }
          });
          break;
        case ErrorId.USER_OWES_INTEREST:
        case ErrorId.USER_OWES_PRINCIPAL_AND_INTEREST:
          openModal('REPAY_OUTSTANDING_BALANCE');
          break;
        case ErrorId.USERS_LAST_PAYBACK_IS_STILL_PROCESSING:
          openModal('LAST_PAYBACK_STILL_PROCESSING');
          break;
        default:
          showErrorToast(t('defaultMessage'));
          closeModal();
          break;
      }
    },
    ...options
  });
};

export default useTerminateCreditMutation;
