import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { ModalProps } from './types';

const WithdrawalAmountInvalid = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('WithdrawalAmountInvalid.title')}>
      <Text>{t('WithdrawalAmountInvalid.info')}</Text>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('WithdrawalAmountInvalid.button')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalAmountInvalid;
