import { UtmTagData } from '../../../utils/tracking';
import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { getApplicationResponseSchema } from './schemas';
import { CreateApplicationResponse, DocumentName, DocumentsResponse } from './types';

export * from './types';

export type CreateApplicationPayload = {
  applicantId: string;
  purchaseTrackingId?: string;
  utm?: UtmTagData;
};
export const createApplication = async (payload: CreateApplicationPayload) => {
  const response = await axiosInstance.post<CreateApplicationResponse>('/uk/credit/applications', payload);
  return response.data;
};

export const getApplication = async (applicationId: string) => {
  const response = await axiosInstance.get(`/uk/credit/applications/${applicationId}`);
  return validateResponseData(getApplicationResponseSchema, response.data);
};

export const selectProductTier = async (applicationId: string, productTierId: string) => {
  const response = await axiosInstance.post<''>(
    `/uk/credit/applications/${applicationId}/product-tier/${productTierId}`
  );
  return response.data;
};

export const getDocuments = async (applicationId: string) => {
  const response = await axiosInstance.get<DocumentsResponse>(
    `/uk/credit/applications/${applicationId}/documents`,
    {
      headers: {
        Accept: 'application/json'
      }
    }
  );
  return response.data;
};

export const getDocumentFile = (applicationId: string, documentName: DocumentName) =>
  axiosInstance.get<Blob>(`/uk/credit/applications/${applicationId}/documents/${documentName}`, {
    headers: {
      Accept: 'application/pdf'
    },
    responseType: 'blob'
  });

export const signDocuments = async (applicationId: string, documents: DocumentName[]) => {
  const response = await axiosInstance.post<''>(`/uk/credit/applications/${applicationId}/documents`, {
    documents
  });
  return response.data;
};

export const finalizeApplication = async (applicationId: string) => {
  const response = await axiosInstance.post<''>(`/uk/credit/applications/${applicationId}/finalize`);
  return response.data;
};
