import { Button, Colors, List, ListItem, ListItemText, Modal, Stack, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { DirectPaymentItem } from '../../../api/SE/paymentProvider/types';
import { FullWidthContainer } from '../../styled';
import { ModalProps } from './types';

type Payload = {
  directPayment: DirectPaymentItem;
};
type Props = ModalProps<Payload>;

const DirectPaymentDetails = ({ closeModal, payload: { directPayment } }: Props) => {
  const { t } = useTranslation(['Modal', 'Button', 'Common']);
  const { items, dueDate } = directPayment;
  const hasDiscount = directPayment.discount > 0;

  return (
    <Modal title={dueDate}>
      <Stack>
        <Text as="span">
          {hasDiscount ? t('DirectPaymentDetails.discountInfo') : t('DirectPaymentDetails.info')}
        </Text>
        <FullWidthContainer>
          <List>
            {'credit' in items ? (
              <ListItem>
                <ListItemText text={t('Common:products.credit')} />
                <ListItemText text={t('Common:money', { amount: items.credit })} />
              </ListItem>
            ) : null}
            {'debtConsolidation' in items ? (
              <ListItem>
                <ListItemText text={t('Common:products.debtConsolidation')} />
                <ListItemText text={t('Common:money', { amount: items.debtConsolidation })} />
              </ListItem>
            ) : null}
            {'paymentProtectionInsurance' in items ? (
              <ListItem>
                <ListItemText text={t('Common:products.paymentProtectionInsurance')} />
                <ListItemText text={t('Common:money', { amount: items.paymentProtectionInsurance })} />
              </ListItem>
            ) : null}
            {hasDiscount ? (
              <ListItem>
                <ListItemText text={t('DirectPayment:Discount.discount')} color={Colors.BRAND_PRIMARY} />
                <ListItemText
                  text={`-${t('Common:money', { amount: directPayment.discount })}`}
                  color={Colors.BRAND_PRIMARY}
                />
              </ListItem>
            ) : null}
          </List>
        </FullWidthContainer>
        <Modal.Actions>
          <Button onClick={closeModal}>{t('Button:clear')}</Button>
        </Modal.Actions>
      </Stack>
    </Modal>
  );
};

export default DirectPaymentDetails;
