import { useQuery } from '@tanstack/react-query';
import API from '../../../api';
import { TransformedTransaction } from '../../../api/user/types';
import { queryKeys } from '../../../api/utils/queryKeys';
import { CustomUseQueryOptions } from '../../../types/reactQuery';

export const useUserTransactionsQuery = <TData = TransformedTransaction[]>(
  options?: CustomUseQueryOptions<TransformedTransaction[], TData>
) =>
  useQuery({
    queryKey: queryKeys.transactions,
    queryFn: API.user.getUserTransactions,
    ...options
  });
