export enum ApplicationRejectReasons {
  USER_BLOCKED = 'UserBlocked',
  OVERDUE_PAYMENTS = 'OverduePayments',
  CII = 'CII',
  DELPHI_SCORE = 'DelphiScore',
  TU_TRENDED_SCORE = 'TuTrendedScore',
  BANKRUPT_IVA = 'Bankrupt_IVA',
  CCJ = 'CCJ',
  DEFAULT = 'Default',
  DELINQUENCY = 'Delinquency',
  RECENT_ACCOUNTS_OPENED = 'RecentAccountsOpened',
  THIN_FILE = 'ThinFile',
  BUREAUS_UNAVAILABLE = 'BureausUnavailable',
  PRE_BUREAU_AFFORDABILITY = 'PreBureauAffordability',
  BUREAU_AFFORDABILITY = 'BureauAffordability',
  INCOME_VERIFICATION = 'IncomeVerification',
  PAYMENT_VERIFICATION = 'PaymentVerification',
  OVERINDEBTEDNESS_DTI = 'OverindebtednessDTI',
  BLOCKED = 'Blocked',
  OVERDUE_STATUS = 'OverdueStatus',
  RECENT_APPLICATION = 'RecentApplication',
  LOW_INCOME = 'LowIncome',
  MIN_AGE = 'MinAge',
  UNEMPLOYED = 'Unemployed',
  ID_V = 'ID_V',
  SANCTIONS = 'Sanctions',
  CIFAS = 'CIFAS'
}
