import { z } from 'zod';
import { ApplicationRejectReasons } from '../../../constants/UK/rejectReasons';

export const createApplicationResponseSchema = z.object({
  id: z.string()
});

export const productTierSchema = z.object({
  id: z.string(),
  amount: z.number(),
  effectiveInterest: z.number(),
  interest: z.number(),
  monthlyPayment: z.number(),
  period: z.number(),
  totalAmount: z.number(),
  totalCost: z.number()
});

export const applicationPendingResponseSchema = z.object({
  status: z.literal('pending'),
  id: z.string()
});

export const applicationRejectedResponseSchema = z.object({
  status: z.literal('rejected'),
  id: z.string(),
  reason: z.nativeEnum(ApplicationRejectReasons)
});
export const applicationManualApprovalResponseSchema = z.object({
  status: z.literal('manualApproval'),
  id: z.string(),
  reasons: z
    .enum([
      'noticeOfCorrection',
      'politicallyExposedPerson',
      'selfEmployedIncomeVerification',
      'partTimeVariableIncome'
    ])
    .array()
});

export const applicationApprovedResponseSchema = z
  .object({
    status: z.literal('approved'),
    id: z.string(),
    offeredProductTiers: productTierSchema.array(),
    authToken: z.string()
  })
  .and(
    z.union([
      z.object({ sourceType: z.literal('ORGANIC') }),
      z.object({
        sourceType: z.literal('BROKER'),
        advertiserName: z.string().optional()
      }),
      // TODO: Remove this once the endpoint has been updated https://fairloo.atlassian.net/browse/FCUK-2104
      z.object({ sourceType: z.undefined().optional() })
    ])
  );

// Using a z.discriminatedUnion causes a type error due to the union in the
// applicationApprovedResponseSchema
export const getApplicationResponseSchema = z.union([
  applicationPendingResponseSchema,
  applicationRejectedResponseSchema,
  applicationManualApprovalResponseSchema,
  applicationApprovedResponseSchema
]);
