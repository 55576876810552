import { AnchorButton, Modal, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { QueryParam } from '../../../../constants';
import { routeMap } from '../../../../routes/routeMap';
import { constructURL } from '../../../../utils/url';
import { ModalProps } from '../types';

type Payload = { redirectTo: string };
type Props = ModalProps<Payload>;

const ResetPaybackPlanDirectDebitMandateNotSet = ({ payload }: Props) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('ResetPaybackPlanDirectDebitMandateNotSet.title')}>
      <Text>{t('ResetPaybackPlanDirectDebitMandateNotSet.info')}</Text>
      <Modal.Actions>
        <AnchorButton
          as={Link}
          to={constructURL(routeMap.bankAccount.root, {
            queryParams: { [QueryParam.REDIRECT_TO]: payload.redirectTo }
          })}
        >
          {t('ResetPaybackPlanDirectDebitMandateNotSet.button')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ResetPaybackPlanDirectDebitMandateNotSet;
