import { Colors, Stack, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatInterestRate } from '../../../localization/percentages';
import { CreditTier } from '../../../types/credit';
import Modal from '../Modal';
import { ModalProps } from './types';

type Payload = {
  installments: CreditTier['pmt'];
  amount: number;
  totalCost: number;
  totalAmount: number;
  interest: number;
  effectiveInterest: number;
};
type Props = ModalProps<Payload>;

const ModalTable = styled.table`
  font-size: 13px;
  width: 100%;
  text-align: center;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 20px;
  color: ${Colors.GRAY_900};

  tbody:before,
  tbody:after {
    content: '-';
    display: block;
    line-height: 1em;
    color: transparent;
  }

  tfoot {
    font-weight: 500;
  }
`;

const PaybackPlan = ({
  payload: { installments, amount, totalCost, totalAmount, interest, effectiveInterest }
}: Props) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('PaybackPlanModal.title')}>
      <Stack spacing={8}>
        <Text>{t('PaybackPlanModal.messageBelowTitle1')}</Text>
        <Text>{t('PaybackPlanModal.messageBelowTitle2')}</Text>
        <ModalTable>
          <thead>
            <tr>
              <th>{t('PaybackPlanModal.DueDate')}</th>
              <th>{t('PaybackPlanModal.Interest')}</th>
              <th>{t('PaybackPlanModal.Amortization')}</th>
              <th>{t('PaybackPlanModal.TotalToPay')}</th>
            </tr>
          </thead>
          <tbody>
            {installments.map((item, index) => (
              <tr key={index}>
                <td>{item.dueDate}</td>
                <td>{item.interest}</td>
                <td>{item.principal}</td>
                <td>{item.total}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('PaybackPlanModal.total')}</td>
              <td>{totalCost}</td>
              <td>{amount}</td>
              <td>{totalAmount}</td>
            </tr>
          </tfoot>
        </ModalTable>
        <Text>
          {t('PaybackPlanModal.messageBelowTable', {
            effectiveInterest: formatInterestRate(effectiveInterest),
            interest: formatInterestRate(interest),
            totalAmount,
            amount,
            totalCost
          })}
        </Text>
      </Stack>
    </Modal>
  );
};

export default PaybackPlan;
